<template>
  <div class="pos">
    <b-card header-tag="header">
      <template #header>
        <h6 class="mb-0">{{ $t('pos.order.title') }}</h6>
      </template>
      <b-card-text v-if="hasordered == false && $store.getters.isMainAccount == true">
        <b-row>
          <b-col sm="12" md="12" lg="8">
            <b-row class="">
              <b-col sm="6" v-for="product in products" :key="product.id" class="p-1">
                <b-card :img-src="'https://' + $store.state.selectedaccount.platform + '.toekomstperspectief.be/api/studentportal/productimage.php?productid=' + product.id" img-top v-if="product.archived != true">
                  <b-card-text>
                    <h4>{{ product.name }}</h4>
                  </b-card-text>
                  <div v-if="product.options == false">
                    <b-button block variant="success" v-on:click="addproducttocart(product.id)"><font-awesome-icon icon="cart-plus" class="fa-lg" />{{ ' € ' + Math.abs(product.price).toFixed(2) }}</b-button>
                  </div>
                  <div v-else>
                    <b-button block variant="success" v-on:click="openproductmodal(product.id)"><font-awesome-icon icon="pen" class="fa-lg" /></b-button>
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
          <b-col sm="12" md="12" lg="4">
            <b-table striped :items="items" :fields="fields" class="cart-table">
              <template #cell(price)="data">
                {{ '€ ' + data.item.price.toFixed(2) }}
              </template>
              <template #cell(buttons)="data">
                <b-button block variant="danger" v-on:click="removeitemfromcart(data.index)"><font-awesome-icon icon="trash" class="fa-lg" /> {{ $t('pos.order.cart.delete') }}</b-button>
              </template>
            </b-table>
            <p class="text-center">{{ $t('pos.currentcredit', {value: credit}) }}</p>
            <b-button block variant="success" :disabled="carttotal == 0" v-on:click="opencheckoutmodal()"><font-awesome-icon icon="check" class="fa-lg" /> {{ $t('pos.order.cart.order', {price : Math.abs(carttotal).toFixed(2)}) }}</b-button>
            <b-button block variant="danger" :disabled="carttotal == 0" v-on:click="clearcart()"><font-awesome-icon icon="times" class="fa-lg" /> {{ $t('pos.order.cart.cancel') }}</b-button>
          </b-col>
        </b-row>
      </b-card-text>
      <b-card-text v-else>
        <span v-if="hasordered == true">{{ $t('pos.order.alreadyordered') }}</span>
        <span v-if="$store.getters.isMainAccount == false">{{ $t('pos.order.notstudent') }}</span>
      </b-card-text>
    </b-card>
    <b-modal id="productmodal" :title="$t('pos.order.modal.title', {productname: productmodal.productname})" hide-footer size="xl">
      <div v-for="option in productmodal.options" :key="option.id">
        <b-form-checkbox v-model="option.enabled" :value="true" :unchecked-value="false" v-on:change="recalculatemodalprice()">{{ $t('pos.order.modal.option', {name: option.name, price: option.price}) }}</b-form-checkbox>
      </div>
      <br>
      <b-button block variant="success" v-on:click="addproductmodal()"><font-awesome-icon icon="check" class="fa-lg" /> {{ $t('pos.order.modal.add', {price : Math.abs(productmodal.price).toFixed(2)}) }}</b-button>
    </b-modal>
    <b-modal id="checkoutmodal" :title="$t('pos.order.checkout.title')" hide-footer size="xl">
      <b-table striped :items="items" :fields="fieldscheckout">
        <template #cell(price)="data">
          {{ '€ ' + data.item.price.toFixed(2) }}
        </template>
        <template #cell(options)="data">
          <span v-for="option in data.item.options" :key="option.id">
            <span v-if="products[data.item.productid].options[option.id].enabled">
              <span v-if="!option.enabled">
                {{ '- ' + option.name }}<br>
              </span>
            </span>
            <span v-else>
              <span v-if="option.enabled">
                {{ '+ ' + option.name }}<br>
              </span>
            </span>
          </span>
        </template>
      </b-table>
      <br>
      <b-button block variant="success" v-on:click="checkout()"><font-awesome-icon icon="check" class="fa-lg" /> {{ $t('pos.order.checkout.checkout') }}</b-button>
    </b-modal>
    <div v-if="topupsenabled">
      <b-button v-b-modal.topupmodal>{{ $t('pos.topupbutton') }}</b-button>
      <br>
    </div>
    <br>
    <b-card header-tag="header">
      <template #header>
        <h6 class="mb-0">{{ $t('pos.transactions') }}</h6>
      </template>
      <b-card-text>
        <div v-for="line in transactions" :key="line.id">
          <div v-if="line.total > 0">
            <b-alert variant="success" show>{{ $t('pos.topup', {time: line.timestamp, value: line.total}) }}</b-alert>
          </div>
          <div v-else>
            <b-alert variant="info" show>{{ $t('pos.charge', {time: line.timestamp, value: line.total}) }}</b-alert>
          </div>
        </div>
      </b-card-text>
    </b-card>
    <b-modal id="topupmodal" :title="$t('pos.modal.title')" centered ok-only :ok-title="$t('general.cancel')">
      <p class="my-4">{{ $t('pos.modal.select') }}</p>
      <b-form-select v-model="topupValue" :options="topupoptions"></b-form-select>
      <br><br>
      <b-button :disabled="topupValue == null" :href="'https://' + $store.state.selectedaccount.platform + '.toekomstperspectief.be/api/studentportal/payment.php?ammount=' + topupValue" >{{ $t('pos.modal.title') }}</b-button>
    </b-modal>
  </div>
</template>

<script>
  import { postapidata } from '@/api'

  export default {
    name: 'Pos',
    data() {
      return {
        transactions: [],
        topupValue: null,
        topupsenabled: false,
        topupoptions: [],
        credit: 0,
        products: [],
        items: [],
        cart: [],
        carttotal: 0,
        fields: [{ key: 'product', label: this.$t('pos.order.cart.product')}, {key: 'price', label: this.$t('pos.order.cart.price')}, {key: 'buttons', label: ' '}],
        fieldscheckout: [{ key: 'product', label: this.$t('pos.order.cart.product')}, {key: 'options', label: this.$t('pos.order.cart.options')}, {key: 'price', label: this.$t('pos.order.cart.price')}],
        productmodal:{
          productname: '',
          productid: null,
          options: [],
          price: null
        },
        hasordered: false,
      }
    },
    created () {
      this.loadData()
    },
    computed: {
      accountselected () {
        if (this.$store.state.selectedaccount.platform != '' && this.$store.state.selectedaccount.session != '') {
          return this.$store.state.selectedaccount.id
        } else {
          return false
        }
      }
    },
    watch: {
      accountselected (newCount, oldCount) {
        if (newCount != oldCount) {
          this.loadData()
        }
      }
    },
    methods: {
      clearcart: function() {
        this.cart = []
        this.carttotal = 0
        this.items = []
      },
      addproducttocart: function(itemid) {
        this.cart.push({productId: itemid, options: false})
        this.recalculatecart()
      },
      removeitemfromcart: function(itemid) {
        if (itemid > -1) {
          this.cart.splice(itemid, 1);
        }
        this.recalculatecart()
      },
      openproductmodal: function(itemid) {
        this.productmodal.productid = itemid
        this.productmodal.productname = this.products[itemid].name
        this.productmodal.options = []
        // make array of options
        for (const property in this.products[itemid].options) {
          this.productmodal.options.push({ ...this.products[itemid].options[property], enabled: this.products[itemid].options[property].defaultenabled})
        }
        this.recalculatemodalprice()
        this.$root.$emit('bv::show::modal', 'productmodal')
      },
      closeproductmodal: function() {
        this.$root.$emit('bv::hide::modal', 'productmodal')
      },
      addproductmodal: function() {
        this.cart.push({productId: this.productmodal.productid, options: this.productmodal.options, price: this.productmodal.price})
        this.recalculatecart()
        this.$root.$emit('bv::hide::modal', 'productmodal')
      },
      recalculatemodalprice: function() {
        this.productmodal.price = Math.abs(parseFloat(this.products[this.productmodal.productid].price))
        for(var i=0; i<this.productmodal.options.length; i++) {
          if (this.productmodal.options[i].enabled) {
            this.productmodal.price += parseFloat(this.productmodal.options[i].price)
          }
        }
      },
      recalculatecart: function() {
        this.items = []
        var totalprice = 0
        for(var i=0; i<this.cart.length; i++){
          var productid = this.cart[i].productId;
          if (this.cart[i].options == false) {
            totalprice = totalprice + Math.abs(parseFloat(this.products[productid].price))
            this.items.push({ price: Math.abs(parseFloat(this.products[productid].price)), product: this.products[productid].name })
          } else {
            totalprice = totalprice + this.cart[i].price
            this.items.push({ price: Math.abs(this.cart[i].price), product: this.products[productid].name, options: this.cart[i].options, productid: productid })
          }
        }
        this.carttotal = totalprice
      },
      opencheckoutmodal: function() {
        this.recalculatecart()
        this.$root.$emit('bv::show::modal', 'checkoutmodal')
      },
      checkout: function() {
        this.recalculatecart()
        var senddata = this.cart
        postapidata( this.$store.state.selectedaccount.platform, this.$store.state.selectedaccount.session, 'possale', senddata, (err) => {
          if (err) {
            if(err.toString() == "Error: notenoughcredit") {
              this.$root.$bvToast.toast(this.$t('pos.order.errorcredit'), {
                title: this.$t('pos.order.errortitle'),
                autoHideDelay: 2500,
                variant: "danger"
              })
            } else {
              this.$root.$bvToast.toast(this.$t('pos.order.errorgeneric'), {
                title: this.$t('pos.order.errortitle'),
                autoHideDelay: 2500,
                variant: "danger"
              })
            }
          } else {
            this.$root.$emit('bv::hide::modal', 'checkoutmodal')
            this.$root.$bvToast.toast(this.$t('pos.order.success'), {
              title: this.$t('pos.order.successtitle'),
              autoHideDelay: 2500,
              variant: "success"
            })
            this.clearcart()
            this.hasordered = true
          }
        })
      },
      loadData () {
        postapidata( this.$store.state.selectedaccount.platform, this.$store.state.selectedaccount.session, 'pos', '', (err, data) => {
          if (err) {
            if (err.toString() == 'Error: Not loggedin') {
              this.$router.push({ name: 'Login'})
            }
          } else {
            this.transactions = data.transactions
            this.topupsenabled = data.topupsenabled
            this.credit = data.credit
            this.products = data.products
            this.hasordered = data.hasordered
            this.topupoptions = []
            for (const value of data.topupvalues) {
              this.topupoptions.push({ value: value, text: value });
            }
          }
        })
      }
    }
  }
</script>