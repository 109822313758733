<template>
  <div class="error">
    <b-card class="text-center">
      <h1>{{ $t('error.title') }}</h1>
      <p>{{ $t('error.needsredirect') }}</p>
    </b-card>
  </div>
</template>

<script>
  export default {
    name: 'Error',
  }
</script>
