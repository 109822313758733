<template>
  <div>
    <b-navbar toggleable="lg" type="dark" variant="primary">
      <b-navbar-brand :to="{ name: 'Home' }">
        <img src="@/assets/nl/logo.svg" class="navbarlogo">
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav v-if="$keycloak.authenticated">
        <b-navbar-nav class="ml-auto">
          <b-nav-item :to="{ name: 'Home' }" :class="{active: $route.name==='Home'}" class="checkda-nav-link">{{ $t("navbar.home") }}</b-nav-item>
          <b-nav-item :to="{ name: 'Calendar' }" :class="{active: $route.name==='Calendar'}" class="checkda-nav-link" v-if="$store.getters.calendarEnabled">{{ $t("navbar.calendar") }}</b-nav-item>
          <b-nav-item :to="{ name: 'Pos' }" :class="{active: $route.name==='Pos'}" class="checkda-nav-link" v-if="$store.getters.posEnabled">{{ $t("navbar.pos") }}</b-nav-item>
          <b-nav-item :to="{ name: 'File' }" :class="{active: $route.name==='File'}" class="checkda-nav-link" v-if="$store.getters.ihpEnabled && ( $store.state.selectedaccount.educationform == 1 || $store.state.selectedaccount.educationform == 2 )">{{ $t("navbar.file") }}</b-nav-item>
          <b-nav-item v-if="1!=1" :to="{ name: 'Apps' }" :class="{active: $route.name==='Apps'}">{{ $t("navbar.apps") }}</b-nav-item>
          <b-nav-item-dropdown right v-b-tooltip.hover>
            <template #button-content>
              <span v-if="$store.state.selectedaccount.id == null">
                {{ $t("navbar.account.noselection") }}
              </span>
              <span v-else>
                {{ $store.state.selectedaccount.studentFirstName }}
              </span>
            </template>
            <b-dropdown-item v-for="account in $store.getters.accountList" :key="account.uuid" @click="switchAccount(account.uuid)">{{ $t("navbar.account.list", { firstname: account.firstname, lastname: account.lastname })}}</b-dropdown-item>
            <b-dropdown-item :to="{ name: 'AccountAdd' }">{{ $t("navbar.account.add") }}</b-dropdown-item>
            <b-dropdown-item v-on:click="logout">{{ $t("navbar.account.logout") }}</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown right v-b-tooltip.hover :title="$t('navbar.language')">
            <template #button-content><font-awesome-icon icon="language" class="fa-lg" /></template>
            <b-dropdown-item v-for="locale in locales" :key="locale" @click="switchLocale(locale)">{{ $t("languages." + locale)}}</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import { postapidatalogin } from '@/api'
export default {
  name: 'Navbar',
  methods: {
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
      }
    },
    switchAccount(uuid) {
      if (uuid == null || uuid == '') {
        return
      }
      // validate if key exists
      if (this.$store.state.accountlist[uuid] == undefined) {
        return
      }
      // fetch info from platform
      var senddata = {}
      senddata.uuid = uuid
      senddata.type = this.$store.state.accountlist[uuid].type
      senddata.student = this.$store.state.accountlist[uuid].student
      postapidatalogin( this.$store.state.accountlist[uuid].platform, this.$keycloak.token, 'login', senddata, (err, data) => {
        if (err) {
          //console.log(err.toString())
        } else {
          this.$store.commit('setAccount', data)
        }
      })
    },
    logout() {
      this.$keycloak.logoutFn()
    }
  },
  data() {
    return {
      locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',')
    }
  }
}
</script>

<style>
.nav-item.nav-item.nav-item a {
  color: #132351;
}
.navbarlogo {
  max-height: 38.48px;
}
</style>
