import { createRouter, createWebHistory } from 'vue-router'

import Home from '../views/Home.vue'
import Pos from '../views/Pos.vue'
import Calendar from '../views/Calendar.vue'
import File from '../views/File.vue'
import AccountAdd from '../views/account/Add.vue'
import ErrorPage from '../views/Error.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: Calendar
  },
  {
    path: '/file',
    name: 'File',
    component: File
  },
  {
    path: '/pos',
    name: 'Pos',
    component: Pos
  },
  {
    path: '/account/add',
    name: 'AccountAdd',
    component: AccountAdd
  },
  {
    path: '/error',
    name: 'Error',
    component: ErrorPage
  },
  {
    path: '/apps',
    name: 'Apps',
    component: () => import(/* webpackChunkName: "apps" */ '../views/Apps.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
