export default {
  "account": {
    "add": {
      "code": {
        "feedbackinvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De code is niet correct."])},
        "feedbackvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De code is correct."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code die u van de school ontvangen heeft."])}
      },
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toevoegen"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De gebruiker is nu aan uw account gekoppeld"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker toevoegen"])}
    }
  },
  "apps": {
    "android": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Android"])},
    "comingsoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Binnenkort beschikbaar!"])},
    "ios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iOS"])},
    "logininstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om aantemelden in de app scan de code hier onder met de app na op de knop te klikken."])},
    "qrcodebutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toon qrcode"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je kan de studenten app downloaden via de links hieronder."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apps"])}
  },
  "calendar": {
    "dateselection": {
      "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruik de cursortoetsen om door kalenderdatums te navigeren"])},
      "nodateselected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen datum geselecteerd"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalender"])},
    "view": {
      "course": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vak: ", _interpolate(_named("course"))])},
      "subject": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Lesonderwerp: ", _interpolate(_named("subject"))])},
      "teacher": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Leerkracht: ", _interpolate(_named("teacher"))])}
    }
  },
  "error": {
    "needsredirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kan dit systeem enkel gebruiken via een link van de school."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is iets misgegaan."])}
  },
  "file": {
    "progress": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voortgang"])}
    }
  },
  "forms": {
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulier resetten"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzenden"])}
  },
  "general": {
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuleer"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkda"])},
    "slogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn school op zak!"])}
  },
  "home": {
    "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalender"])},
    "currentcredit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Huidig bedrag op kaart: € ", _interpolate(_named("value")), "."])},
    "noaccount": {
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koppel gebruiker."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nog geen gebruiker gekoppeld."])}
    },
    "welcome": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Welkom ", _interpolate(_named("firstname")), " ", _interpolate(_named("lastname"))])}
  },
  "languages": {
    "en-US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "fr-BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français de Belgique"])},
    "fr-FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français"])},
    "nl-BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlaams"])},
    "nl-NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nederlands"])}
  },
  "navbar": {
    "account": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker toevoegen"])},
      "list": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("firstname")), " ", _interpolate(_named("lastname"))])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afmelden"])},
      "noselection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No user selected"])}
    },
    "apps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apps"])},
    "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalender"])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossier"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taal"])},
    "pos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Middageten"])}
  },
  "pos": {
    "charge": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Op ", _interpolate(_named("time")), " is er een aankoop gedaan van ", _interpolate(_named("value")), "."])},
    "currentcredit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Huidig bedrag op kaart: € ", _interpolate(_named("value")), "."])},
    "modal": {
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer het gewenste bedrag om bij te laden."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bijladen"])}
    },
    "order": {
      "alreadyordered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U heeft vandaag al besteld."])},
      "cart": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak winkelmand leeg"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijder"])},
        "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opties"])},
        "order": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bestellen € ", _interpolate(_named("price"))])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijs"])},
        "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])}
      },
      "checkout": {
        "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestelling plaatsen"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellen"])}
      },
      "errorcredit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je hebt niet voldoende geld op je kaart staan."])},
      "errorgeneric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is iets misgegaan, probeer opnieuw"])},
      "errortitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout"])},
      "modal": {
        "add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Toevoegen € ", _interpolate(_named("price"))])},
        "option": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " (€ ", _interpolate(_named("price")), ")"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Product: ", _interpolate(_named("productname"))])}
      },
      "notstudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enkel een leerling kan een bestelling plaatsen"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je bestelling is geplaatst"])},
      "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online bestellen"])}
    },
    "topup": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Op ", _interpolate(_named("time")), " is er ", _interpolate(_named("value")), " bijgeladen."])},
    "topupbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bijladen"])},
    "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transacties"])}
  }
}