import $ from "jquery";

export function postapidata(platform, session, endpoint, datainput, cb){

  var dataToSend = { 'platform': platform, 'session': session, 'endpoint': endpoint, 'data': datainput };

  $.ajax({

    type: 'post',
    url: 'https://' + platform + '.toekomstperspectief.be/api/studentportal/' + endpoint + '.php',
    data: JSON.stringify(dataToSend),
    contentType: "application/json",
    dataType: 'JSON',
    success: function(response){

      if (response.success == true) {

        cb(null, response.data)

      } else if ("error" in response) {

        cb(new Error(response.error))

      } else {

        cb(new Error('Comms error.'))

      }

    },
    error: function() {

      cb(new Error('Comms error.'))

    }

  });

}

export function postapidatalocal(token, endpoint, datainput, cb){

  var dataToSend = { 'token': token, 'data': datainput };

  $.ajax({

    type: 'post',
    url: 'https://app.checkda.be/api/' + endpoint + '.php',
    data: JSON.stringify(dataToSend),
    contentType: "application/json",
    dataType: 'JSON',
    success: function(response){

      if (response.success == true) {

        cb(null, response.data)

      } else if ("error" in response) {

        cb(new Error(response.error))

      } else {

        cb(new Error('Comms error.'))

      }

    },
    error: function() {

      cb(new Error('Comms error.'))

    }

  });

}

export function postapidatalogin(platform, token, endpoint, datainput, cb){

  var dataToSend = { 'token': token, 'data': datainput };

  $.ajax({

    type: 'post',
    url: 'https://' + platform + '.toekomstperspectief.be/api/studentportal/' + endpoint + '.php',
    data: JSON.stringify(dataToSend),
    contentType: "application/json",
    dataType: 'JSON',
    success: function(response){

      if (response.success == true) {

        cb(null, response.data)

      } else if ("error" in response) {

        cb(new Error(response.error))

      } else {

        cb(new Error('Comms error.'))

      }

    },
    error: function() {

      cb(new Error('Comms error.'))

    }

  });

}


