<template>
  <div class="file">
    <b-card no-body v-if="loaded == true">
      <b-tabs card>
        <b-tab :title="$t('file.progress.title')" v-if="$store.state.selectedaccount.educationform == 1 || $store.state.selectedaccount.educationform == 2">
          <b-card-text>
            <div v-for="progress in filedata.progress" :key="progress.id">
              <div v-if="progress.valuepreviousyear != 0 && progress.valuethisyear !=0">
                <h3>{{ progress.name }}</h3>
                <b-progress class="mb-3" height="2rem" max="100">
                  <b-progress-bar :value="progress.valuepreviousyear" variant="primary"></b-progress-bar>
                  <b-progress-bar :value="progress.valuethisyear - progress.valuepreviousyear" variant="success"></b-progress-bar>
                </b-progress>
              </div>
            </div>
          </b-card-text>
        </b-tab>        
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
  import { postapidata } from '@/api'

  export default {
    name: 'File',
    data() {
      return {
        loaded: false,
        filedata: {},
      }
    },
    created () {
      if (this.$store.state.selectedaccount.platform == null) {
        this.$router.push({ name: 'Error'})
      } else {
        this.loadData()     
      }
    },
    computed: {
      accountselected () {
        if (this.$store.state.selectedaccount.platform != '' && this.$store.state.selectedaccount.session != '') {
          return this.$store.state.selectedaccount.id
        } else {
          return false
        }
      }
    },
    watch: {
      accountselected (newCount, oldCount) {
        if (newCount != oldCount) {
          this.loadData()
        }
      }
    },
    methods: {
      loadData () {
        this.loaded = false
        postapidata( this.$store.state.selectedaccount.platform, this.$store.state.selectedaccount.session, 'file', {}, (err, data) => {
          if (err) {
            this.error = err.toString()
            if (err.toString() == 'Error: Not loggedin') {
              this.$router.push({ name: 'Login'})
            }
          } else {
            this.filedata = data.file
            this.loaded = true
          }
        })
      },
    }
  }
</script>