import Vue from 'vue'
import { createApp } from 'vue'
import App from './App.vue'
import * as Sentry from "@sentry/vue";
import { BootstrapVue } from 'bootstrap-vue'
import VueKeyCloak from '@dsb-norge/vue-keycloak-js'
import { postapidatalocal, postapidatalogin } from '@/api'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faAngleDoubleDown, faLanguage, faShoppingCart, faCheck, faTimes, faCartPlus, faPen, faTrash } from '@fortawesome/pro-solid-svg-icons'
import { faGooglePlay, faAppStore } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add( faAngleDoubleDown, faGooglePlay, faAppStore, faLanguage, faShoppingCart, faCheck, faTimes, faCartPlus, faPen, faTrash )

Vue.component('font-awesome-icon', FontAwesomeIcon)

import './app.scss'
import router from './router'
import i18n from './i18n'
import store from './store'

Vue.use(BootstrapVue)

Vue.config.productionTip = false

const app = createApp(App)

Sentry.init({
  app,
  dsn: "https://22af519f93d742febe859546d0889a43@o1113613.ingest.sentry.io/4505181690068992",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(VueKeyCloak, {
  initOptions: {
    flow: 'standard',
    checkLoginIframe: false,
    onLoad: 'login-required',
  },
  config: {
    url: 'https://keycloak.toekomstperspectief.be',
    realm: 'Checkda',
    clientId: 'app-checkda-be'
  },
  onReady: kc => {
    postapidatalocal( kc.token, 'getuserplatformlist', {}, (err, data) => {
      if (err) {
        if (err.toString() == 'Error: Not loggedin') {
          router.push({ name: 'Error'})
        }
      } else {
        store.commit('setAccountList', data.list);
        if (Object.keys(store.state.accountlist).length == 1) {
          var uuid = store.state.accountlist[Object.keys(store.state.accountlist)[0]].uuid;
          var senddata = {}
          senddata.uuid = uuid
          senddata.type = store.state.accountlist[uuid].type
          senddata.student = store.state.accountlist[uuid].student
          postapidatalogin( store.state.accountlist[uuid].platform, kc.token, 'login', senddata, (err, data) => {
            if (err) {
              //console.log(err.toString())
            } else {
              store.commit('setAccount', data)
            }
          })
        } else if (Object.keys(store.state.accountlist).length == 0) {
          router.push({ name: 'AccountAdd'})
        }
      }
    })
  },
})

app.use(store)
app.use(i18n)
app.use(router)
app.mount("#app");