import { createStore } from 'vuex'

export default createStore({
  state: {
    accountlist: {},
    selectedaccount: {
      id: null,
      studentFirstName: '',
      studentLastName: '',
      accountFirstName: '',
      accountLastName: '',
      isMainAccount: null,
      isCoAccount: null,
      nrCoAccount: null,
      classid: null,
      educationform: null,
      platform: '',
      session: '',
      modules: {
        posEnabled: false,
        ihpEnabled: false,
        calendarEnabled: false,
      }
    },
  },
  mutations: {
    setAccountList (state, object) {
      state.accountlist = object
    },
    setAccount (state, item) {
      state.selectedaccount.platform = item.platform
      state.selectedaccount.session = item.sessionid

      state.selectedaccount.id = item.studentinfo.studentid
      state.selectedaccount.studentFirstName = item.studentinfo.studentFirstName
      state.selectedaccount.studentLastName = item.studentinfo.studentLastName
      state.selectedaccount.accountFirstName = item.studentinfo.accountFirstName
      state.selectedaccount.accountLastName = item.studentinfo.accountLastName
      state.selectedaccount.educationform = item.studentinfo.educationform
      state.selectedaccount.classid = item.studentinfo.classid

      state.selectedaccount.isMainAccount = item.studentinfo.isMainAccount
      state.selectedaccount.isCoAccount = item.studentinfo.isCoAccount
      state.selectedaccount.nrCoAccount = item.studentinfo.nrCoAccount

      state.selectedaccount.modules.posEnabled = item.modules.calendarenabled
      state.selectedaccount.modules.ihpEnabled = item.modules.ihpenabled
      state.selectedaccount.modules.calendarEnabled = item.modules.posenabled
    },
  },
  getters: {
    isMainAccount: (state) => {
      if (state.selectedaccount.isMainAccount==true) {
        return true
      }
      return false
    },
    studentFirstName: (state) => {
      if (state.selectedaccount.studentFirstName != undefined) {
        return state.selectedaccount.studentFirstName
      }
      return null
    },
    studentLastName: (state) => {
      if (state.selectedaccount.studentLastName != undefined) {
        return state.selectedaccount.studentLastName
      }
      return null
    },
    accountFirstName: (state) => {
      if (state.selectedaccount.accountFirstName != undefined) {
        return state.selectedaccount.accountFirstName
      }
      return null
    },
    accountLastName: (state) => {
      if (state.selectedaccount.accountLastName != undefined) {
        return state.selectedaccount.accountLastName
      }
      return null
    },
    posEnabled: (state) => {
      if (state.selectedaccount.modules.posEnabled != undefined) {
        return state.selectedaccount.modules.posEnabled
      }
      return false
    },
    ihpEnabled: (state) => {
      if (state.selectedaccount.modules.ihpEnabled != undefined) {
        return state.selectedaccount.modules.ihpEnabled
      }
      return false
    },
    calendarEnabled: (state) => {
      if (state.selectedaccount.modules.calendarEnabled != undefined) {
        return state.selectedaccount.modules.calendarEnabled
      }
      return false
    },
    accountList: (state) => {
      if (state.accountlist != undefined) {
        return state.accountlist
      }
      return {}
    },
  },
  actions: {
  },
  modules: {
  },
  strict: process.env.NODE_ENV !== 'production'
})
