<template>
  <div class="home">
    <h3 v-if="$keycloak.tokenParsed != null">{{ $t('home.welcome', {firstname: $keycloak.tokenParsed.given_name, lastname: $keycloak.tokenParsed.family_name}) }}</h3>
    <div v-if="Object.keys(this.$store.state.accountlist).length == 0">
      <br>
      <b-card header-tag="header">
        <template #header>
          <h6 class="mb-0">{{ $t('home.noaccount.title') }}</h6>
        </template>
        <b-card-text>
          <b-row>
            <b-col>
              <div><b-button :to="{ name: 'AccountAdd' }" variant="primary">{{ $t("home.noaccount.button") }}</b-button></div>
            </b-col>
          </b-row>
        </b-card-text>
      </b-card>
    </div>
    <br>
    <div v-if="$store.state.selectedaccount.id != null">
      <b-card header-tag="header" v-for="post in posts" :key="post.id">
        <template #header>
          <h6 class="mb-0">{{ post.title }}</h6>
        </template>
        <b-card-text>
          <b-row>
            <b-col>
              <div v-html="post.content"></div>
            </b-col>
          </b-row>
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>
  import { postapidata } from '@/api'

  export default {
    name: 'Home',
    data() {
      return {
        posts: [],
      }
    },
    created () {
      this.loadData()
    },
    computed: {
      accountselected () {
        if (this.$store.state.selectedaccount.platform != '' && this.$store.state.selectedaccount.session != '') {
          return this.$store.state.selectedaccount.id
        } else {
          return false
        }
      }
    },
    watch: {
      accountselected (newCount, oldCount) {
        if (newCount != oldCount) {
          this.loadData()
        }
      }
    },
    methods: {
      loadData () {
        if (this.$store.state.selectedaccount.platform != '' && this.$store.state.selectedaccount.session != '') {
          postapidata( this.$store.state.selectedaccount.platform, this.$store.state.selectedaccount.session, 'home', {}, (err, data) => {
            if (err) {
              this.error = err.toString()
              if (err.toString() == 'Error: Not loggedin') {
                this.$router.push({ name: 'Login'})
              }
            } else {
              this.posts = data.posts
            }
          })
        } else {
          this.posts = []
        }
      },
    }
  }
</script>
