export default {
  "account": {
    "add": {
      "code": {
        "feedbackinvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "feedbackvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un utilisateur"])}
    }
  },
  "apps": {
    "android": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Android"])},
    "comingsoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bientôt disponible!"])},
    "ios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iOS"])},
    "logininstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour vous connecter à l'application, scannez le code ci-dessous avec l'application après avoir cliqué sur le bouton."])},
    "qrcodebutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher le code QR"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez télécharger l'application étudiante via les liens ci-dessous."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["applications"])}
  },
  "calendar": {
    "dateselection": {
      "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisez les touches du curseur pour naviguer dans les dates du calendrier"])},
      "nodateselected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune date sélectionnée"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendrier"])},
    "view": {
      "course": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Objet : ", _interpolate(_named("course"))])},
      "subject": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sujet de la leçon : ", _interpolate(_named("subject"))])},
      "teacher": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Enseignant : ", _interpolate(_named("teacher"))])}
    }
  },
  "error": {
    "needsredirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez utiliser ce système que via un lien de l'école."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelque chose s'est mal passé."])}
  },
  "file": {
    "progress": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    }
  },
  "forms": {
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["formulaire de réinitialisation"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expédier"])}
  },
  "general": {
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkla"])},
    "slogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon école dans ma poche!"])}
  },
  "home": {
    "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendrier"])},
    "currentcredit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Montant actuel sur la carte : ", _interpolate(_named("value")), " € ."])},
    "noaccount": {
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "welcome": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bienvenue ", _interpolate(_named("firstname")), " ", _interpolate(_named("lastname"))])}
  },
  "languages": {
    "en-US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "fr-BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français de Belgique"])},
    "fr-FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français"])},
    "nl-BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlaams"])},
    "nl-NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nederlands"])}
  },
  "navbar": {
    "account": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un utilisateur"])},
      "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "noselection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "apps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applications"])},
    "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendrier"])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])},
    "pos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déjeuner"])}
  },
  "pos": {
    "charge": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le ", _interpolate(_named("time")), " , un achat de ", _interpolate(_named("value")), "a été effectué."])},
    "currentcredit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Montant actuel sur la carte : ", _interpolate(_named("value")), " € ."])},
    "modal": {
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le montant que vous souhaitez recharger."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recharger"])}
    },
    "order": {
      "alreadyordered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez déjà commandé aujourd'hui."])},
      "cart": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panier vide"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enlever"])},
        "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les options"])},
        "order": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Commande ", _interpolate(_named("price")), " €"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix"])},
        "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit"])}
      },
      "checkout": {
        "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commander"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande"])}
      },
      "errorcredit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas assez d'argent sur votre carte."])},
      "errorgeneric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite. Veuillez réessayer"])},
      "errortitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erreur"])},
      "modal": {
        "add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ajouter € ", _interpolate(_named("price"))])},
        "option": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " (€ ", _interpolate(_named("price")), ")"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Produit : ", _interpolate(_named("productname"))])}
      },
      "notstudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seul un étudiant peut passer une commande"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre commande a bien été reçue"])},
      "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonne chance"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commander en ligne"])}
    },
    "topup": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([" ", _interpolate(_named("value")), " a été chargé sur ", _interpolate(_named("time")), " ."])},
    "topupbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recharger"])},
    "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions"])}
  }
}