<template>
  <div class="calendar">
    <b-card header-tag="header" no-body>
      <template #header>
        <h6 class="mb-0">{{ $t('calendar.title') }}</h6>
      </template>
      <b-card-text class="d-none d-sm-block">
        <b-row>
          <b-col cols="3" class="pr-0">
            <b-calendar block v-model="selectedDate" @context="datepickerUpdate" :locale="$i18n.locale" start-weekday="1" :label-help="$t('calendar.dateselection.help')" :label-no-date-selected="$t('calendar.dateselection.nodateselected')"></b-calendar>
          </b-col>
          <b-col class="pl-0">
            <DayPilotCalendar id="dp" :config="config" ref="calendar" />
          </b-col>
        </b-row>
      </b-card-text>
      <b-card-text class="d-sm-none">
        <b-form-datepicker block v-model="selectedDate" @context="datepickerSMUpdate" :locale="$i18n.locale" start-weekday="1" :label-help="$t('calendar.dateselection.help')" :label-no-date-selected="$t('calendar.dateselection.nodateselected')"></b-form-datepicker>
        <DayPilotCalendar id="dpday" :config="configday" ref="calendarday" />
      </b-card-text>
    </b-card>
    <b-modal id="viewItemModal" hide-footer size="lg">
      <template #modal-title>
        <span>{{ viewModal.title }}</span>
      </template>
      <div>
        <span v-if="viewModal.course != ''">{{ $t('calendar.view.course', { course: viewModal.course }) }}<br></span>
        <span v-if="viewModal.teacher != ''">{{ $t('calendar.view.teacher', { teacher: viewModal.teacher }) }}<br></span>
        <span v-if="viewModal.subject != ''">{{ $t('calendar.view.subject', { subject: viewModal.subject }) }}<br></span>
        <div v-if="viewModal.description != ''" v-html="viewModal.description"></div>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import { postapidata } from '@/api'
  import { DayPilotCalendar } from '@daypilot/daypilot-lite-vue'

  export default {
    name: 'Calendar',
    components: {
      DayPilotCalendar
    },
    data() {
      return {
        config: {
          locale: this.$i18n.locale,
          viewType: "Week",
          showAllDayEvents: true,
          cellDuration: 10,
          cellHeight: 20,
          businessBeginsHour: 8,
          businessEndsHour: 17,
          timeRangeSelectedHandling: "Disabled",
          eventDeleteHandling: "Disabled",
          eventMoveHandling: "Disabled",
          eventResizeHandling: "Disabled",
          onEventClick: async (args) => {
            this.viewModal.visible = false
            if (args.e.data.type == 'agenda') {
              this.viewModal.id = args.e.data.id
              this.viewModal.title = args.e.data.text
              this.viewModal.course = args.e.data.data.courseName
              this.viewModal.data = args.e.data.data
              this.viewModal.subject = args.e.data.data.lessonSubject
              this.viewModal.teacher = args.e.data.data.teacherName
              this.viewModal.description = ''
              this.$bvModal.show('viewItemModal')
              this.$nextTick(() => {
                this.viewModal.visible = true
              })
            } else if (args.e.data.type == 'class') {
              this.viewModal.id = args.e.data.id
              this.viewModal.title = args.e.data.text
              this.viewModal.course = ''
              this.viewModal.data = {}
              this.viewModal.subject = ''
              this.viewModal.teacher = ''
              this.viewModal.description = args.e.data.description
              this.$bvModal.show('viewItemModal')
              this.$nextTick(() => {
                this.viewModal.visible = true
              })
            }
          },
        },
        configday: {
          locale: this.$i18n.locale,
          viewType: "Day",
          showAllDayEvents: true,
          cellDuration: 10,
          cellHeight: 20,
          businessBeginsHour: 8,
          businessEndsHour: 17,
          timeRangeSelectedHandling: "Disabled",
          eventDeleteHandling: "Disabled",
          eventMoveHandling: "Disabled",
          eventResizeHandling: "Disabled",
          onEventClick: async (args) => {
            this.viewModal.visible = false
            if (args.e.data.type == 'agenda') {
              this.viewModal.id = args.e.data.id
              this.viewModal.title = args.e.data.text
              this.viewModal.course = args.e.data.data.courseName
              this.viewModal.data = args.e.data.data
              this.viewModal.subject = args.e.data.data.lessonSubject
              this.viewModal.teacher = args.e.data.data.teacherName
              this.viewModal.description = ''
              this.$bvModal.show('viewItemModal')
              this.$nextTick(() => {
                this.viewModal.visible = true
              })
            } else if (args.e.data.type == 'class') {
              this.viewModal.id = args.e.data.id
              this.viewModal.title = args.e.data.text
              this.viewModal.course = ''
              this.viewModal.data = {}
              this.viewModal.subject = ''
              this.viewModal.teacher = ''
              this.viewModal.description = args.e.data.description
              this.$bvModal.show('viewItemModal')
              this.$nextTick(() => {
                this.viewModal.visible = true
              })
            }
          },
        },
        selectedDate: '',
        viewModal: {
          title: '',
          subject: '',
          teacher: '',
          course: '',
          description: '',
          data: {},
          visible: false,
        },
      }
    },
    computed: {
      calendar() {
        return this.$refs.calendar.control;
      },
      calendarday() {
        return this.$refs.calendarday.control;
      },
      accountselected () {
        if (this.$store.state.selectedaccount.platform != '' && this.$store.state.selectedaccount.session != '') {
          return this.$store.state.selectedaccount.id
        } else {
          return false
        }
      }
    },
    created () {
      if (this.$store.state.selectedaccount.platform == null) {
        this.$router.push({ name: 'Error'})
      } else {
        const yourDate = new Date()
        this.selectedDate = yourDate.toISOString().split('T')[0]
        this.$nextTick(() => {
          this.loadData()
        })       
      }
    },
    watch: {
      '$i18n.locale': function(newVal) {
        this.calendar.update({locale: newVal});
        this.calendarday.update({locale: newVal});
      },
      accountselected (newCount, oldCount) {
        if (newCount != oldCount) {
          this.loadData()
        }
      }
    },
    methods: {
      loadData () {
        postapidata( this.$store.state.selectedaccount.platform, this.$store.state.selectedaccount.session, 'calendar', { start: this.calendar.visibleStart().value, end: this.calendar.visibleEnd().value}, (err, data) => {
          if (err) {
            this.error = err.toString()
            if (err.toString() == 'Error: Not loggedin') {
              this.$router.push({ name: 'Login'})
            }
          } else {
            var events = [];
            this.events = data.events
            events = data.events
            this.calendar.update({events});
            this.calendarday.update({events});
          }
        })
      },
      datepickerUpdate(ctx) {
        if(ctx.selectedYMD != '') {
          this.calendar.update({startDate: ctx.selectedYMD});
          this.calendarday.update({startDate: ctx.selectedYMD});
          this.loadData();
        }
      },
      datepickerSMUpdate(ctx) {
        if(ctx.selectedYMD != '') {
          this.calendar.update({startDate: ctx.selectedYMD});
          this.loadData();
        }
      },
    }
  }
</script>