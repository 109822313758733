<template>
    <div class="accountadd">
      <b-alert v-model="success" variant="success" dismissible>
        {{ $t('account.add.success') }}
      </b-alert>
      <b-card header-tag="header">
        <template #header>
          <h6 class="mb-0">{{ $t('account.add.title') }}</h6>
        </template>
        <b-card-text>
          <b-form @submit="onSubmit" v-if="showform">
            <b-form-group id="input-group-1" :label="$t('account.add.code.label')" label-for="code-input">
              <b-form-input id="code-input" v-model="code" type="text" required :state="codevalidation"></b-form-input>
              <b-form-invalid-feedback :state="codevalidation">
                {{ $t('account.add.code.feedbackinvalid') }}
              </b-form-invalid-feedback>
              <b-form-valid-feedback :state="codevalidation">
                {{ $t('account.add.code.feedbackvalid') }}
              </b-form-valid-feedback>
            </b-form-group>
            <b-button type="submit" variant="primary">{{ $t('account.add.submit') }}</b-button>
          </b-form>
        </b-card-text>
      </b-card>
    </div>
  </template>
  
  <script>
    import { postapidatalocal } from '@/api'
  
    export default {
      name: 'AccountAdd',
      data() {
        return {
          showform: true,
          code: '',
          success: false,
        }
      },
      computed: {
        codevalidation() {
          if (this.code == '') {
            return null
          }
          const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
          return regexExp.test(this.code)
        }
      },
      methods: {
        onSubmit(event) {
          event.preventDefault()
          this.success = false
          var senddata = {}
          senddata.code = this.code
          postapidatalocal( this.$keycloak.token, 'addaccount', senddata, (err) => {
            if (err) {
              if (err.toString() == 'Error: Not loggedin') {
                this.$router.push({ name: 'Error'})
              } else {
                //console.log(err.toString())
              }
            } else {
              // reload account list
              postapidatalocal( this.$keycloak.token, 'getuserplatformlist', {}, (err, data) => {
                if (err) {
                  if (err.toString() == 'Error: Not loggedin') {
                    this.$router.push({ name: 'Error'})
                  }
                } else {
                  this.$store.commit('setAccountList', data.list);
                  this.success = true
                }
              })
            }
          })
        },
      }
    }
  </script>