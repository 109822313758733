export default {
  "account": {
    "add": {
      "code": {
        "feedbackinvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The code is not correct"])},
        "feedbackvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The code is correct"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user has been linked to your account"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add user"])}
    }
  },
  "apps": {
    "android": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Android"])},
    "comingsoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coming soon!"])},
    "ios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iOS"])},
    "logininstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To login into the app scan the qrcode below with the app after pressing the button."])},
    "qrcodebutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show qrcode"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can download our student companion app from the links below."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apps"])}
  },
  "calendar": {
    "dateselection": {
      "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the cursor keys to navigate through calendar dates"])},
      "nodateselected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No date selected"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar"])},
    "view": {
      "course": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Course: ", _interpolate(_named("course"))])},
      "subject": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Subject: ", _interpolate(_named("subject"))])},
      "teacher": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Teacher: ", _interpolate(_named("teacher"))])}
    }
  },
  "error": {
    "needsredirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can only access this system from a school provided link."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong."])}
  },
  "file": {
    "progress": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progress"])}
    }
  },
  "forms": {
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset form"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])}
  },
  "general": {
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkda"])},
    "slogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My school in my pocket!"])}
  },
  "home": {
    "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar"])},
    "currentcredit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Current credit: € ", _interpolate(_named("value")), "."])},
    "noaccount": {
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add user."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No user has been linked yet."])}
    },
    "welcome": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Welcome ", _interpolate(_named("firstname")), " ", _interpolate(_named("lastname"))])}
  },
  "languages": {
    "en-US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "fr-BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français de Belgique"])},
    "fr-FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français"])},
    "nl-BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlaams"])},
    "nl-NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nederlands"])}
  },
  "navbar": {
    "account": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add user"])},
      "list": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("firstname")), " ", _interpolate(_named("lastname"))])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
      "noselection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No user selected"])}
    },
    "apps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apps"])},
    "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar"])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
    "pos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunch"])}
  },
  "pos": {
    "charge": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["On ", _interpolate(_named("time")), " there was a charge for € ", _interpolate(_named("value"))])},
    "currentcredit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Current credit: € ", _interpolate(_named("value")), "."])},
    "modal": {
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the amount you want to topup the card with."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topup"])}
    },
    "order": {
      "alreadyordered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have already ordered today."])},
      "cart": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty cart"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
        "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])},
        "order": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Order € ", _interpolate(_named("price"))])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
        "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])}
      },
      "checkout": {
        "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place order"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])}
      },
      "errorcredit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have enough money on your card."])},
      "errorgeneric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong, try again"])},
      "errortitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
      "modal": {
        "add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Add € ", _interpolate(_named("price"))])},
        "option": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " (€ ", _interpolate(_named("price")), ")"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Product: ", _interpolate(_named("productname"))])}
      },
      "notstudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only a student can place a order"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your order has been placed"])},
      "successtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order online"])}
    },
    "topup": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["On ", _interpolate(_named("time")), " there was a topup for € ", _interpolate(_named("value"))])},
    "topupbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topup card"])},
    "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions"])}
  }
}